/**
 * Replaces curly braces with a variable
 * @param inputString - full string to replace in
 * @param replacementString - string to replace with
 * @param variable - name of the variable to replace without curly brackets
 * @returns input string with replaced variable
 *
 * @example
 * replaceCurlyBrackesWithVariable('Hello {{name}}', 'name', 'John Doe')
 * // 'Hello John Doe'
 *
 * replaceCurlyBrackesWithVariable('Document signed the {{date}} at {{time}}', 'time', '11:40')
 * // 'Document signed the {{date}} at 11:40'
 */
export const replaceCurlyBrackesWithVariable = (
  inputString: string,
  variable: string,
  replacementString: string,
) => {
  return inputString.replace(
    new RegExp(`{{${variable}}}`, 'g'),
    replacementString,
  );
};
