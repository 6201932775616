<template>
  <br />
</template>

<script>
export default {
  name: "SbBreak",
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
