<template>
  <footer
    v-if="footer"
    :data-many-scroller="footerScroller"
    data-many-scroller-event-id="event-footer"
    aria-label="Footer"
    class="footer relative bg-[--bg-color] text-[--text-color]"
    :style="{
      '--bg-color': backgroundColor.hex,
      '--text-color': backgroundColor.hexFg,
    }"
  >
    <ElementPattern
      v-if="footer.backgroundPattern?.length"
      :bloks="footer.backgroundPattern"
      :fadeOut="true"
      objectFit="cover"
      className="absolute top-0 left-0 w-full h-full z--1"
    />
    <div class="relative clamp-pt-8-18 text-center flex flex-col min-h-60vh">
      <div class="relative flex-1 flex flex-col">
        <div class="footer-buttons z-footer-text page-mx clamp-mb-8-16">
          <BaseFooterNavPrimary />
        </div>
        <div class="flex-1">
          <div
            class="flex flex-col items-center tl:items-start page-px tl:clamp-mb-8-16 clamp-gap-8-16 h-full clamp-pt-8-16"
          >
            <BaseFooterLinks
              v-if="footer.linksPrimary?.length || footer.linksPrimaryHeadline"
              :headline="footer.linksPrimaryHeadline"
              :links="footer.linksPrimary"
              alignment="primary"
              class="z-footer-text flex-1 justify-center"
            />
            <BaseFooterLinks
              v-if="
                footer.linksSecondary?.length || footer.linksSecondaryHeadline
              "
              :headline="footer.linksSecondaryHeadline"
              :links="footer.linksSecondary"
              alignment="secondary"
              class="z-footer-text flex-1 justify-center"
            />
            <ARichText
              v-if="
                footer.additionalText?.content?.[0] &&
                footer.additionalText?.content?.[0].content
              "
              :richtext="footer.additionalText"
              class="footer-additional-text flex flex-col items-center justify-center text-center tl:items-start tl:text-left"
            />
          </div>
        </div>
        <ElementMedia
          v-if="footer.media?.length"
          :bloks="footer.media"
          ratio="orig"
          :sizes="[{ bpMin: '0', vw: 70 }]"
          class="footer-image tl:absolute z-footer-media mb-0 ml:mt--10 right-0 bottom-0"
          :class="{
            'h-full w-auto': imgAspect,
          }"
        />
      </div>

      <SbCandleBanner />
      <div class="page-mx clamp-py-2-4 text-body-xs">
        {{ footer.copyrightText }}
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import useConfig from '@/composables/useConfig';
import { ISbPluginThemeColorPicker } from '@/types';
import { useManyScrollerOptions } from '@/composables/useManyScrollerOptions';
import { useHeader } from '@/composables/useHeader';

const { config } = useConfig();
const { footerScroller } = useManyScrollerOptions();
const { setHideHeader } = useHeader();

const footer = computed(() => config.value?.footer?.[0]);
const backgroundColor = computed(
  () => footer.value?.backgroundColor as ISbPluginThemeColorPicker,
);

const footerImgPath = computed(
  () =>
    footer.value?.media?.[0].image.filename.replace(
      'https://a.storyblok.com/',
      '',
    ),
);

const imgAspect = computed(() => {
  let ratioW = 16;
  let ratioH = 9;

  const getWH = footerImgPath.value.split('/')[2];
  ratioW = Number(getWH.split('x')[0]);
  ratioH = Number(getWH.split('x')[1]);
  const aspect = ratioW / ratioH;
  return aspect;
});
const onEnter = (event: Event) => {
  setHideHeader(true);
};
const onLeave = (event: Event) => {
  setHideHeader(false);
};

onMounted(() => {
  document.addEventListener('event-footer-full-enter', onEnter);
  document.addEventListener('event-footer-full-exit', onLeave);
});
onUnmounted(() => {
  document.removeEventListener('event-footer-full-enter', onEnter);
  document.removeEventListener('event-footer-full-exit', onLeave);
});
</script>

<style scoped lang="scss">
.footer {
  content-visibility: auto;

  & .footer-buttons {
    opacity: calc(1 * var(--scroll-position-enter));
  }

  & .footer-image {
    transform: translateY(calc(10% * var(--scroll-position-enter) - 5%));
  }
  .footer-additional-text {
    &:deep(p) {
      @apply text-body-sm;
    }
  }
}
</style>
