<template>
  <b><slot /></b>
</template>

<script>
export default {
  name: "SbBold",
  props: {
    blok: {
      type: Object,
      required: false,
    },
  },
};
</script>
