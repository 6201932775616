<template>
  <div
    class="absolute top-0 left-0 w-full h-full"
    :class="[{ 'fade-out': fadeOut }]"
  >
    <AImage
      :src="blok.image.filename"
      :alt="blok.image.alt"
      :focalPoint="blok.image.focus"
      ratio="orig"
      :sizes="[{ bpMin: '0', vw: 100 }]"
      loading="lazy"
      class="absolute top-0 left-0 w-full bg-image"
      :class="{
        'h-full object-cover': blok.objectFit === 'cover',
        'h-auto': blok.objectFit === 'original',
      }"
    />
  </div>
</template>

<script setup lang="ts">
import { SectionBackgroundImageStoryblok } from '@/types';

export interface Props {
  blok: SectionBackgroundImageStoryblok;
  fadeOut?: boolean;
}
const { blok, fadeOut } = withDefaults(defineProps<Props>(), {
  fadeOut: true,
});
</script>

<style scoped lang="scss">
.bg-image {
  &.fade-out {
    // fade out from top to bottom
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    > div {
      // fade in during the top 200px
      mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0px,
        rgba(0, 0, 0, 1) 200px
      );
    }
  }
  // > div::before {
  //   // set the background color and mask it with the image
  //   @apply absolute top-0 left-0 w-full h-full;
  //   content: '';
  //   background-color: var(--bg-color);
  //   mask-image: var(--bg-image);
  //   mask-repeat: repeat;
  //   mask-position: top center;
  //   mask-size: 100% auto;
  // }

  // &.obj-fit-cover {
  //   > div::before {
  //     mask-size: cover;
  //     mask-repeat: no-repeat;
  //   }
  // }

  // &.obj-fit-contain {
  //   > div::before {
  //     mask-size: contain;
  //     mask-repeat: no-repeat;
  //   }
  // }

  // &.obj-fit-stretch {
  //   > div::before {
  //     mask-size: 100% 100%;
  //     mask-repeat: no-repeat;
  //   }
  // }

  // &.pos-bottom-left {
  //   > div::before {
  //     mask-position: bottom left;
  //   }
  // }

  // &.pos-bottom-center {
  //   > div::before {
  //     mask-position: bottom center;
  //   }
  // }
}
</style>
