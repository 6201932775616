<template>
  <div
    class="flex items-center clamp-gap-8-8"
    role="menubar"
    aria-label="Social Media"
  >
    <SbElementSocialMediaButton
      v-for="link in config?.socialMediaButtons"
      :key="link._uid"
      :blok="link"
      :size="size"
      role="menuitem"
    />
  </div>
</template>

<script setup lang="ts">
import useConfig from '@/composables/useConfig';

const { config } = useConfig();

interface Props {
  size?: 'sm' | 'md' | 'lg';
}

withDefaults(defineProps<Props>(), {
  size: 'sm',
});
</script>
