<template>
  <code><slot /></code>
</template>

<script>
export default {
  name: "SbCode",
  props: {
    blok: {
      type: Object,
      required: false,
    },
  },
};
</script>
