<template>
  <blockquote>
    <template v-for="item in blok.content" :key="item.id">
      <component :blok="item" :is="item.type" />
    </template>
  </blockquote>
</template>

<script>
export default {
  name: "SbQuote",
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
