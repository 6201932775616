import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    name: 'MapOverview',
    path: '/:pathMatch(.*)*/map',
    component: () => import('@/views/Page.vue'),
    children: [
      {
        name: 'MapLocation',
        path: ':location',
        component: () => import('@/views/PageMapLocation.vue'),
      },
    ],
  },
  {
    name: 'Page',
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/Page.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // if only params or hash has changed, don't scroll to top
    if (to.path === from.path) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
    // scroll to top on route path change
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ left: 0, top: 0 });
        }
      }, 500);
    });
  },
});

export default router;
