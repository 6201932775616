<template>
  {{ blok.text }}
</template>

<script>
export default {
  name: "SbText",
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
};
</script>


<style lang="scss" scoped>
</style>