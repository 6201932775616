<template>
  <code :class="classes">
    <template v-for="item in blok.content" :key="item.id">
      <component :blok="item" :is="item.type" />
    </template>
  </code>
</template>

<script>
export default {
  name: "SbCode",
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    classes() {
      let output = "";
      if (this.blok.attrs && this.blok.attrs.class) {
        output = this.blok.attrs.class;
      }
      return output;
    },
  },
};
</script>
