/////////////////////////////////
// Create and update debug msg //
/////////////////////////////////

// Create parent container for debug msg
export const createScrollDebugMsgContainer = (div: HTMLElement) => {
  let containerDebugMsg: HTMLElement;
  // Get scroll attribute

  // Check if the parent has a child with class 'scroll-msg'
  const childWithScrollMsgClass = div.querySelector(".scroll-debug-msg");

  // If it doesn't exist, create a new div element
  if (!childWithScrollMsgClass) {
    containerDebugMsg = document.createElement("div");
    containerDebugMsg.style.position = "sticky";
    containerDebugMsg.style.top = "0";
    containerDebugMsg.style.height = "0";
    containerDebugMsg.style.fontWeight = "900";
    containerDebugMsg.style.fontFamily = "Arial";
    containerDebugMsg.style.zIndex = "10";
    containerDebugMsg.classList.add("scroll-debug-msg");
    div.insertBefore(containerDebugMsg, div.firstChild);
  }
};

// Create debug msg for type
const createScrollTypeDebugMsgContainer = (
  containerDebugMsg: HTMLElement,
  type: string,
  hieraki: string | "primary"
) => {
  // Check if the parent has a child with class 'scroll-msg'
  let hasDebugContainer = false;
  let containerDebugMsgType;
  let containerDebugMsgTypeObj;

  if (containerDebugMsg) {
    const childWithScrollMsgClass = containerDebugMsg.querySelector("." + type);
    // If it doesn't exist, create a new div element
    if (!childWithScrollMsgClass) {
      containerDebugMsgType = document.createElement("div");
      containerDebugMsgType.style.width = "auto";
      containerDebugMsgType.style.display = "inline-block";
      containerDebugMsgType.style.backgroundColor = "white";
      containerDebugMsgType.style.color = "black";
      containerDebugMsgType.style.padding = "5px";
      containerDebugMsgType.style.fontWeight = "400";
      containerDebugMsgType.style.fontSize = "12px";
      containerDebugMsgType.style.lineHeight = "12px";
      containerDebugMsgType.style.fontFamily = "Arial";
      containerDebugMsgType.style.margin = "5px";
      containerDebugMsgType.style.border = "1px solid black";
      containerDebugMsgType.style.borderRadius = "0.5rem";

      if (hieraki === "secondary") {
        containerDebugMsgType.style.backgroundColor = "#020202";
        containerDebugMsgType.style.color = "white";
      }
      //containerDebugMsgType.style.boxShadow = "0 0 5px 0 rgba(0, 0, 0, 0.5)";
      containerDebugMsgType.classList.add(type);
      containerDebugMsg.appendChild(containerDebugMsgType);

      containerDebugMsgTypeObj = containerDebugMsg.querySelector("." + type);
    }
    hasDebugContainer = true;
  }

  return { hasDebugContainer, containerDebugMsgTypeObj };
};

// Update debug msg values for type
export const useScrollDebugMsg = (
  div: HTMLElement,
  position: number,
  type: string,
  hieraki: string | "primary"
) => {
  const containerDebugMsg = div?.querySelector(
    ".scroll-debug-msg"
  ) as HTMLElement;

  if (containerDebugMsg) {
    const childWithScrollMsgClass = containerDebugMsg.querySelector("." + type);
    if (!childWithScrollMsgClass) {
      // Create div element of type
      createScrollTypeDebugMsgContainer(containerDebugMsg, type, hieraki);
    } else {
      // Update values debug msg values
      childWithScrollMsgClass.innerHTML =
        type + ": " + Math.floor(position * 100) + "%";
    }
  }
};
