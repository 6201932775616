<template>
  <button
    class="button-hover rounded-full font-bold flex clamp-gap-2-2 items-center justify-center disabled:pointer-events-none disabled:opacity-50 disabled:filter-grayscale-100"
    :class="[
      `mask-${maskName}`,
      {
        'disable-hover-effect': props.disableHoverEffect,
        'not-mounted': !isMounted,
        'text-body-xs clamp-h-8-10': size === 'xs',
        'text-body-sm clamp-h-10-15': size === 'sm',
        'text-body-md clamp-h-12-20': size === 'md',
        'clamp-px-3-4': size === 'xs' && kind === 'text',
        'clamp-px-4-6': (size === 'sm' || size === 'md') && kind === 'text',
        'clamp-w-8-10': size === 'xs' && kind === 'icon',
        'clamp-w-10-15': size === 'sm' && kind === 'icon',
        'clamp-w-12-20': size === 'md' && kind === 'icon',
      },
    ]"
    :style="{
      '--button-bg': buttonBg,
      '--button-border': buttonBorder,
      '--button-color': buttonColor,
      '--button-color-hover': buttonColorHover,
      '--steps': mask.frameCount - 1,
      '--sprite-width': mask.width,
      '--sprite-height': mask.height,
      '--mask-size-width': `${mask.frameCount * 100}%`,
      '--mask-min-width': '15rem',
    }"
    :type="type"
    :disabled="disabled"
    :ariaLabel="kind === 'icon' ? ariaLabel : text"
    @mouseenter="onEnter"
    @mouseleave="onLeave"
  >
    <Transition name="fade" mode="out-in" :duration="100">
      <AIcon v-if="icon" :icon="icon" :key="icon" :size="size" />
    </Transition>
    <Transition name="fade" mode="out-in" :duration="100">
      <span
        v-if="kind === 'text'"
        :key="text"
        :class="{
          'clamp-leading-3-4': size === 'xs',
          'clamp-leading-4-5': size === 'sm',
          'clamp-leading-5-6': size === 'md',
        }"
        >{{ text }}</span
      >
    </Transition>
  </button>
</template>

<script setup lang="ts">
import { Fa6SolidIcon } from '@/types';
import useConfig from '@/composables/useConfig';

const { primaryColor } = useConfig();

// common and optional props
export type PropsButtonBase = {
  type?: 'button' | 'submit' | 'reset';
  size?: 'xs' | 'sm' | 'md';
  variant?:
    | 'primary'
    | 'primary-outline'
    | 'primary-transparent'
    | 'secondary'
    | 'secondary-outline'
    | 'secondary-transparent';
  // the props below has to be optional on both text and icon buttons - otherwise vue complains
  icon?: Fa6SolidIcon;
  text?: string;
  ariaLabel?: string;
  disabled?: boolean;
  disableHoverEffect?: boolean;
};

// specific props for text and icon buttons
export type PropsTextButton = PropsButtonBase & {
  kind: 'text';
  text: string;
};
export type PropsIconButton = PropsButtonBase & {
  kind: 'icon';
  icon: Fa6SolidIcon;
  ariaLabel: string;
};

export type Props = PropsTextButton | PropsIconButton;

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  variant: 'primary',
});

const isMounted = ref(false);
onMounted(() => {
  // wait for the first render to be done
  setTimeout(() => (isMounted.value = true), 1000);
});

const buttonBg = computed(() => {
  switch (props.variant) {
    case 'primary':
    case 'primary-outline':
      return 'white';
    case 'secondary-outline':
    case 'secondary':
      return primaryColor.value?.hex || 'black';
    case 'primary-transparent':
    case 'secondary-transparent':
      return 'transparent';
  }
});
const buttonBorder = computed(() => {
  switch (props.variant) {
    case 'primary':
    case 'primary-transparent':
    case 'secondary-outline':
      return 'white';
    case 'secondary':
    case 'secondary-transparent':
    case 'primary-outline':
      return primaryColor.value?.hex || 'black';
  }
});
const buttonColor = computed(() => {
  switch (props.variant) {
    case 'primary':
    case 'primary-outline':
    case 'secondary-transparent':
      return primaryColor.value?.hex || 'black';
    case 'primary-transparent':
    case 'secondary-outline':
    case 'secondary':
      return 'white';
  }
});
const buttonColorHover = computed(() => {
  switch (props.variant) {
    case 'primary':
    case 'primary-outline':
    case 'secondary-transparent':
      return 'white';
    case 'primary-transparent':
    case 'secondary-outline':
    case 'secondary':
      return primaryColor.value?.hex || 'black';
  }
});

const shuffle = (array: string[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

type MaskName = 'sprite-g' | 'sprite-m' | 'sprite-n';
type MaskConfig = {
  [key in MaskName]: {
    width: number;
    height: number;
    frameCount: number;
  };
};

const maskConfig: MaskConfig = {
  'sprite-g': {
    width: 480,
    height: 270,
    frameCount: 40,
  },
  'sprite-m': {
    width: 480,
    height: 270,
    frameCount: 31,
  },
  'sprite-n': {
    width: 480,
    height: 270,
    frameCount: 21,
  },
};
const maskNames = Object.keys(maskConfig) as MaskName[];

// set random first mask
const maskName = ref(shuffle(maskNames)[0] as MaskName);
const mask = computed(() => maskConfig[maskName.value]);

const timeout = ref<NodeJS.Timeout | null>(null);
const onEnter = () => timeout.value && clearTimeout(timeout.value);
const onLeave = () => {
  timeout.value = setTimeout(() => {
    // after leave animation is done pick a new random mask - make sure it's not the same as the current one
    const masks = shuffle(
      maskNames.filter((n) => n !== maskName.value),
    ) as MaskName[];
    maskName.value = masks[0];
  }, 700);
};
</script>

<style scoped lang="scss">
.button-hover {
  @apply relative cursor-pointer overflow-hidden rounded-full;
  @apply ui-border;
  border-color: var(--button-border);
  background-color: var(--button-bg);
  color: var(--button-color);

  &:not(.disable-hover-effect) {
    @apply transition-transform duration-500 ease-out-circ;
  }

  &::before {
    @apply absolute content-[''] top-50% left-50% translate-[-50%] w-full h-full pointer-events-none;
    min-width: var(--mask-min-width);
    min-height: calc(
      var(--sprite-height) / var(--sprite-width) * var(--mask-min-width)
    );
    background-color: var(--button-color);
    mask-size: var(--mask-size-width) 100%;
    mask-position: 0 50%;
    animation: aniOut 700ms steps(var(--steps)) forwards;
  }

  &.not-mounted::before {
    animation-duration: 0ms;
  }

  // make sure the icon and text is in front
  > * {
    @apply relative z-button-text;
    @apply transition-all duration-350 delay-350;
  }

  &:not(.disable-hover-effect) {
    @include supports-hover {
      @apply transition-transform duration-500 ease-out-circ;
      &:hover {
        @apply scale-105;
        color: var(--button-bg);
        > * {
          @apply delay-0;
          color: var(--button-color-hover);
        }
        &::before {
          animation: aniIn 700ms steps(var(--steps)) forwards;
        }
      }
    }
  }

  &.mask-sprite-g::before {
    mask-image: url('@/assets/masks/sprite-g.png');
  }
  &.mask-sprite-m::before {
    mask-image: url('@/assets/masks/sprite-m.png');
  }
  &.mask-sprite-n::before {
    mask-image: url('@/assets/masks/sprite-n.png');
  }
}

@keyframes aniIn {
  from {
    mask-position: 0 50%;
  }
  to {
    mask-position: 100% 50%;
  }
}

@keyframes aniOut {
  from {
    mask-position: 100% 50%;
  }
  to {
    mask-position: 0 50%;
  }
}
</style>
