<template>
  <div
    v-if="candleBanner"
    :class="`bg-gradient-to-r from-[--from-color] to-[--to-color] text-white`"
    :style="{
      '--from-color': colorLeft,
      '--to-color': colorRight,
    }"
  >
    <div class="page-mx flex justify-end items-center clamp-gap-4-4 h-candle">
      <span class="text-body-xs">{{ candleBanner.text }}</span>
      <AImage
        :src="candleBanner.logo.filename"
        :alt="candleBanner.logo.alt"
        :focalPoint="candleBanner.logo.focus"
        ratio="orig"
        :sizes="[{ bpMin: '0', vw: 5 }]"
        loading="eager"
        class="opacity-100 transition-none clamp-w-12-12"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import useConfig from '@/composables/useConfig';
import { ISbPluginThemeColorPicker } from '@/types';

const { config } = useConfig();
const candleBanner = computed(() => config.value?.candleBanner?.[0]);

const colorLeft = computed(
  () => (candleBanner.value?.colorLeft as ISbPluginThemeColorPicker).hex,
);
const colorRight = computed(
  () => (candleBanner.value?.colorRight as ISbPluginThemeColorPicker).hex,
);
</script>

<style scoped></style>
