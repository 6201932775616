//////////////////////////////////////
// Scroll Enter                     //
// var(--scroll-position-enter)     //
// Amount: window height * distance //
//////////////////////////////////////
import { useScrollDebugMsg } from "./useScrollDebugMsg.js";
import { useInview } from "./handleInview.js";
import { useBreakpoints } from "./handleBreakpoints.js";
import { OptionFull } from "./../types";

export const useScrollFull = (
  options: OptionFull,
  div: HTMLElement,
  rect: DOMRect,
  top: number,
  windowHeight: number,
  optionsBreakpoints: number[],
  elementOffsetTop: number,
  eventCallback: Function,
  hasDebug: boolean
) => {
  // Use offsets
  let getOffsetStart = options.offsetStart;
  let getOffsetEnd = options.offsetEnd;
  let getScrollerEventType = options.event;
  let scrollerType = options.type || "inview-once";
  let offsetDistanceStart = 0;
  let offsetDistanceEnd = 0;
  let position = 0;
  let scrollDistance = 0;
  let topOffset = 0;

  // Handle offsets
  if (windowHeight > elementOffsetTop) {
    // In first view
    if (
      getOffsetStart &&
      useBreakpoints(optionsBreakpoints, getOffsetStart) * windowHeight >=
        windowHeight - elementOffsetTop
    ) {
      topOffset = elementOffsetTop - windowHeight;
      offsetDistanceStart = useBreakpoints(optionsBreakpoints, getOffsetStart);
    }
    if (
      getOffsetStart &&
      useBreakpoints(optionsBreakpoints, getOffsetStart) * windowHeight >=
        windowHeight
    ) {
      offsetDistanceStart = useBreakpoints(optionsBreakpoints, getOffsetStart);
    }
    if (
      getOffsetEnd &&
      useBreakpoints(optionsBreakpoints, getOffsetEnd) * windowHeight <
        rect.height
    ) {
      offsetDistanceEnd = useBreakpoints(optionsBreakpoints, getOffsetEnd);
    }
  } else {
    if (getOffsetStart) {
      offsetDistanceStart = useBreakpoints(optionsBreakpoints, getOffsetStart);
    }
    if (getOffsetEnd) {
      offsetDistanceEnd = useBreakpoints(optionsBreakpoints, getOffsetEnd);
    }
  }

  // Offset start and exit distance in pixels
  let offsetPixelStart = windowHeight * offsetDistanceStart;
  let offsetPixelEnd = windowHeight * offsetDistanceEnd;

  // If element is in first view, calculate the top offset, for shorter scroll distance
  if (elementOffsetTop < windowHeight) {
    topOffset = topOffset + windowHeight - elementOffsetTop;
  }
  // Calculate the scroll distance
  if (
    top + offsetPixelStart < rect.height + (windowHeight - rect.height) &&
    top - offsetPixelEnd > -Math.abs(rect.height)
  ) {
    scrollDistance =
      rect.height +
      windowHeight -
      topOffset -
      offsetPixelEnd -
      offsetPixelStart;
    let parallaxValue = Math.abs(
      top +
        offsetPixelStart -
        (rect.height + (windowHeight - rect.height) - topOffset)
    );
    position = (1 / scrollDistance) * parallaxValue;
  } else if (windowHeight - offsetPixelStart <= top) {
    position;
  } else {
    position = 1;
  }

  // Update scroller progress
  position = Math.round(position * 10000) / 10000;
  if (scrollerType === "progress") {
    (div as HTMLElement).style.setProperty(
      "--scroll-position-full",
      position.toString()
    );
  }

  // Use inview - handles inview class and events
  useInview(
    div,
    getScrollerEventType,
    position,
    scrollerType,
    eventCallback,
    "full"
  );

  // Show debug
  if (hasDebug) {
    // Progress debug
    if (scrollerType === "progress") {
      useScrollDebugMsg(div, position, "Full", "primary");
    }
    // Offset start debug
    if (offsetDistanceStart > 0) {
      useScrollDebugMsg(
        div,
        offsetDistanceStart,
        "Offset-start-full",
        "secondary"
      );
    }
    // Offset end debug
    if (offsetDistanceEnd > 0) {
      useScrollDebugMsg(div, offsetDistanceEnd, "Offset-end-full", "secondary");
    }
  }
};
