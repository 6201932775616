<template>
  <AVideo
    :blok="blok"
    :autoplay="autoplay"
    :muted="muted"
    :loop="loop"
    :controls="controls"
    :ratio="ratio"
    :sizes="sizes"
    ref="videoRef"
  />
</template>

<script setup lang="ts">
import { ImgRatio, ImgSizes } from '@/components/atoms/AImage.vue';
import { MediaVideoStoryblok } from '@/types';
import AVideo from '@/components/atoms/AVideo.vue';

interface Props {
  blok: MediaVideoStoryblok;
  // for video
  autoplay?: boolean;
  loop?: boolean;
  controls?: boolean;
  muted?: boolean;
  // for poster image
  ratio: ImgRatio;
  sizes?: ImgSizes;
}
defineProps<Props>();

const videoRef = ref<InstanceType<typeof AVideo> | null>(null);

const play = () => videoRef.value?.play();
const pause = () => videoRef.value?.pause();

defineExpose({
  play,
  pause,
});
</script>
