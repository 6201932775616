<template>
  <span :class="elementClass">
    <slot />
  </span>
</template>

<script>
import { ref } from "vue";
export default {
  name: "SbStyled",
  props: {
    blok: {
      type: Object,
      default: () => { },
    },
  },
  setup({ blok }) {
    const elementClass = ref(blok.attrs.class);

    return {
      elementClass,
    };
  },
};
</script>
