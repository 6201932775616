<template>
  <component :is="tag">

    <template v-for="item in blok.content" :key="item.id">
      <template v-if="item.marks">
        <MarkCompile :blok="item" :marks="item.marks" />
      </template>
      <template v-else>
        {{ item.text }}
      </template>
    </template>
  </component>
</template>

<script>
export default {
  name: "SbHeading",
  props: {
    blok: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    tag() {
      return "h" + this.blok.attrs.level;
    },
  },
};
</script>
