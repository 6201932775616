<template>
  <div
    class="relative w-full aspect-[--aspect]"
    :style="{ '--aspect': aspectRatio }"
  >
    <!-- :class="$attrs.class" -->
    <AVideoHls
      v-if="blok.component === 'MediaVideo' && cloudflareVideo.details"
      ref="videoRef"
      :hlsPlaylistUrl="cloudflareVideo.details.playbackHls"
      :autoplay="autoplay"
      :loop="loop"
      :controls="controls"
      :muted="muted"
      @play="onPlay"
      @pause="onPause"
      @ended="onEnded"
      @canPlay="onCanPlay"
      class="w-full h-full object-cover"
    />
    <!-- :class="$attrs.class" -->
    <AVideoTransparent
      v-else-if="blok.component === 'MediaVideoAnimationTransparent'"
      ref="videoRef"
      :videoMp4Url="blok.videoMp4.filename"
      :videoWebmUrl="blok.videoWebm.filename"
      :autoplay="autoplay"
      :loop="loop"
      :controls="controls"
      @play="onPlay"
      @pause="onPause"
      @ended="onEnded"
      @canPlay="onCanPlay"
    />
    <div
      class="absolute top-50% left-50% translate-[-50%] w-full h-full overflow-hidden pointer-events-none"
      :class="{
        'pointer-events-auto': !playing,
      }"
    >
      <Transition name="fade" :duration="400">
        <div v-show="!playing && ended" class="relative w-full h-full">
          <AImage
            :class="[
              'w-full h-full',
              {
                'pointer-events-auto': !playing && ended,
                'object-cover': blok.component === 'MediaVideo',
                'object-contain':
                  blok.component === 'MediaVideoAnimationTransparent',
              },
            ]"
            :src="blok.posterImage.filename"
            :alt="blok.posterImage.alt"
            :focalPoint="blok.posterImage.focus"
            :ratio="ratio"
            :sizes="sizes"
          />
          <Transition name="fade" :duration="400">
            <div
              v-if="
                (canPlay &&
                  controlsInitiated &&
                  (!autoplay || (!playing && ended))) ||
                controls
              "
              class="absolute top-50% left-50% translate-[-50%] transition-opacity duration-400"
              :class="{
                'pointer-events-auto': !playing,
              }"
            >
              <AIconButton
                icon="play"
                :ariaLabel="playing ? 'Pause' : 'Play'"
                @click="onToggle"
              />
            </div>
          </Transition>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ImgRatio, ImgSizes } from '@/components/atoms/AImage.vue';
import { DatasourceAspect } from '@/types';
import {
  MediaVideoAnimationTransparentStoryblok,
  MediaVideoStoryblok,
} from '@/types';

interface Props {
  blok: MediaVideoStoryblok | MediaVideoAnimationTransparentStoryblok;
  // for video
  autoplay?: boolean;
  loop?: boolean;
  controls?: boolean;
  muted?: boolean;
  // for poster image
  ratio: ImgRatio;
  sizes?: ImgSizes;
}
const props = defineProps<Props>();

import { ISbPluginCloudflareVideo } from '@/types';
const cloudflareVideo = computed(
  () => props.blok.cloudflareVideoId as ISbPluginCloudflareVideo,
);

const aspectRatio = computed(() => {
  let w = 16;
  let h = 9;

  if (props.blok.component === 'MediaVideoAnimationTransparent') {
    const aspect = props.blok.aspect as DatasourceAspect;
    const aspectSplit = aspect.split(':');
    w = parseInt(aspectSplit[0]);
    h = parseInt(aspectSplit[1]);
  } else if (props.blok.component === 'MediaVideo') {
    w = cloudflareVideo.value.details?.width ?? 16;
    h = cloudflareVideo.value.details?.height ?? 9;
  }

  return w / h;
});

const videoRef = ref<HTMLVideoElement | null>(null);
const controlsInitiated = ref(false);
const playing = ref(false);
const ended = ref(true);
const canPlay = ref(false);

const onPlay = () => {
  ended.value = false;
  playing.value = true;
};

const onPause = () => {
  playing.value = false;
};

const onEnded = () => {
  playing.value = false;
  ended.value = true;
};

const onCanPlay = () => {
  canPlay.value = true;
  setTimeout(() => {
    controlsInitiated.value = true;
  }, 250);
};

const play = () => videoRef.value?.play();
const pause = () => videoRef.value?.pause();

const onToggle = () => {
  if (playing.value) pause();
  else play();
};

defineExpose({
  play,
  pause,
});
</script>
