<template>
  <ALink
    :to="{ linktype: 'story', cached_url: url }"
    :ariaLabel="`Go to ${config?.title} homepage`"
    class="shrink-0 pointer-events-auto"
  >
    <AVideoTransparent
      v-if="animated && config?.logoAnimated?.[0]?.videoMp4?.filename"
      :videoMp4Url="config?.logoAnimated?.[0].videoMp4.filename"
      :videoWebmUrl="config?.logoAnimated?.[0].videoWebm.filename"
      :autoplay="true"
      :loop="false"
      :controls="false"
      class="opacity-100 transition-none clamp-w-28-56"
    />
    <AImage
      v-else-if="config?.logo.filename"
      :src="config.logo.filename"
      :alt="config.title"
      :focalPoint="config?.logo.focus"
      ratio="orig"
      :sizes="[
        {
          bpMin: '0',
          vw: 20,
        },
      ]"
      loading="eager"
      class="opacity-100 transition-none clamp-w-25-50"
    />
  </ALink>
</template>

<script setup lang="ts">
import useConfig from '@/composables/useConfig';

withDefaults(
  defineProps<{
    animated?: boolean;
  }>(),
  {
    animated: false,
  },
);

const { config, brand } = useConfig();
const url = computed(() => {
  const isEditor = import.meta.env.MODE === 'editor';
  if (isEditor) return `/${brand.value}/`;
  return '/';
});
</script>
