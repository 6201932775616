<template>
  <ul>
    <li v-for="list in blok.content" :key="list.id">
      <template v-for="item in list.content" :key="item.id">
        <component :blok="item" :is="item.type" />
      </template>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SbUnorderedList",
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
