//////////////////////////////////////
// Scroll Enter                     //
// var(--scroll-position-enter)     //
// Amount: window height * distance //
//////////////////////////////////////
import { useScrollDebugMsg } from './useScrollDebugMsg.ts';
import { useInview } from './handleInview.js';
import { useBreakpoints } from './handleBreakpoints.js';
import { OptionEnter } from './../types';

export const useScrollEnter = (
  options: OptionEnter,
  div: HTMLElement,
  top: number,
  windowHeight: number,
  optionsBreakpoints: number[],
  elementOffsetTop: number,
  eventCallback: Function,
  hasDebug: boolean,
) => {
  // Options
  let getScrollDistance = options.distance || false;
  let scrollerType = options.type || 'inview-once';
  let getScrollerEventType = options.event || undefined;
  let scrollDistance = 0;

  // Remove offsets
  if (windowHeight > elementOffsetTop) {
    // In first view
  } else {
    if (getScrollDistance) {
      scrollDistance = useBreakpoints(optionsBreakpoints, getScrollDistance);
    }
  }

  let position = 0;
  let topEnterOffset = 0;
  let distancePixelEnter = windowHeight * scrollDistance;
  let enterDistance = windowHeight - distancePixelEnter;

  if (enterDistance >= 0) {
    if (
      enterDistance <= top + topEnterOffset &&
      top <= windowHeight - topEnterOffset
    ) {
      position = Math.abs(
        (1 / distancePixelEnter) * (top - windowHeight + topEnterOffset),
      );
    }
    if (enterDistance > top + topEnterOffset) {
      position = 1;
    }
  } else {
    position = 1;
  }

  position = Math.round(position * 10000) / 10000;
  if (scrollerType === 'progress') {
    (div as HTMLElement).style.setProperty(
      '--scroll-position-enter',
      position.toString(),
    );
  }

  // Use inview - handles inview class and events
  useInview(
    div,
    getScrollerEventType,
    position,
    scrollerType,
    eventCallback,
    'enter',
  );

  // Show debug
  if (hasDebug) {
    // Progress debug
    if (scrollerType === 'progress' && scrollDistance > 0) {
      useScrollDebugMsg(div, position, 'Enter', 'primary');
    }
  }
};
