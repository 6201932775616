import useConfig from "./useConfig"

type Theme = {
  bg: string
  text: string
}

const theme = reactive<Theme>({
  bg: 'white',
  text: 'black',
})

export default function useSectionTheme() {
  const { primaryColor } = useConfig()

  const setTheme = ({ bg, text }: Theme) => {
    theme.bg = bg
    theme.text = text
    // TODO: set theme on body? so when scrolling too far up it's not white in the background
  }

  // set theme based on brand
  if (primaryColor.value) {
    setTheme({ bg: primaryColor.value.hex, text: primaryColor.value.hexFg });
  }

  // intersection observer sets the theme
  const observeThemeSection = (elRef: Ref<HTMLElement | null>, colors: ComputedRef<Theme>) => {
    const observer = ref<IntersectionObserver | null>(null);
    const inView = ref(false);
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // console.log('entry', entry.intersectionRatio)
          setTheme({ bg: colors.value.bg, text: colors.value.text });
          inView.value = true;
        } else {
          inView.value = false;
        }
      });
    };
    onMounted(() => {
      observer.value = new IntersectionObserver(handleIntersect, {
        root: null,
        rootMargin: '-50% 0px',
        threshold: [0],
      });
      if (elRef.value) observer.value.observe(elRef.value);
    });
    onUnmounted(() => {
      if (observer.value) observer.value.disconnect();
    });

    return { inView }
  }

  return {
    theme,
    setTheme,
    observeThemeSection,
  }
}