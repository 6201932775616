//////////////////////////////////////
// Scroll Enter                     //
// var(--scroll-position-enter)     //
// Amount: window height * distance //
//////////////////////////////////////
import { useScrollDebugMsg } from "./useScrollDebugMsg.js";
import { useInview } from "./handleInview.js";
import { useBreakpoints } from "./handleBreakpoints.js";
import { OptionExit } from "./../types";

export const useScrollExit = (
  options: OptionExit,
  div: HTMLElement,
  rect: DOMRect,
  top: number,
  windowHeight: number,
  optionsBreakpoints: number[],
  elementOffsetTop: number,
  eventCallback: Function,
  hasDebug: boolean
) => {
  // Options
  let getScrollDistance = options.distance || [0];
  let scrollerType = options.type || "inview-once";
  let getScrollerEventType = options.event;
  let scrollDistance = 0;

  // Handle distances
  if (windowHeight > elementOffsetTop) {
    // In first view
    if (
      getScrollDistance &&
      useBreakpoints(optionsBreakpoints, getScrollDistance) * windowHeight >=
        rect.height - elementOffsetTop
    ) {
      let lessDistance = rect.height / windowHeight;
      scrollDistance =
        useBreakpoints(optionsBreakpoints, getScrollDistance) * lessDistance;
    }
    if (
      getScrollDistance &&
      useBreakpoints(optionsBreakpoints, getScrollDistance) * windowHeight <
        rect.height
    ) {
      scrollDistance = useBreakpoints(optionsBreakpoints, getScrollDistance);
    }
  } else {
    if (getScrollDistance) {
      scrollDistance = useBreakpoints(optionsBreakpoints, getScrollDistance);
    }
  }

  let position = 0;
  let positiveTopVal = Math.abs(top);
  let distancePixelExit = windowHeight * scrollDistance;
  let exitDistance = rect.height - distancePixelExit;

  // Rect bigger than the exit distance
  if (distancePixelExit > 0) {
    if (rect.height > distancePixelExit) {
      if (top <= 0) {
        if (positiveTopVal > exitDistance && positiveTopVal < rect.height) {
          position = Math.abs(
            (1 / distancePixelExit) * (rect.height + top - distancePixelExit)
          );
        } else {
          position = 0;
        }
      }
      if (rect.height + top < 0) {
        position = 1;
      }
    }
    // Rect smaller than the exit distance
    else {
      if (distancePixelExit >= top + rect.height && top + rect.height > 0) {
        position = Math.abs(
          (1 / distancePixelExit) * (top + rect.height - distancePixelExit)
        );
      } else {
        position = 0;
      }
    }
  } else {
    position = 1;
  }

  position = Math.round(position * 10000) / 10000;
  if (scrollerType === "progress") {
    (div as HTMLElement).style.setProperty(
      "--scroll-position-exit",
      position.toString()
    );
  }

  // Use inview - handles inview class and events
  useInview(
    div,
    getScrollerEventType,
    position,
    scrollerType,
    eventCallback,
    "exit"
  );

  // Show debug
  if (hasDebug) {
    // Progress debug
    if (scrollerType === "progress" && scrollDistance > 0) {
      useScrollDebugMsg(div, position, "Exit", "primary");
    }
  }
};
