<template>
  <template v-for="element in bloks" :key="element._uid">
    <SbMediaImage
      v-if="element.component === 'MediaImage'"
      :blok="element"
      :class="$attrs.class"
      :ratio="ratio"
      :sizes="sizes"
      :loading="loading"
      v-editable="element"
    />
    <SbMediaVideo
      v-else-if="element.component === 'MediaVideo'"
      :blok="element"
      :class="$attrs.class"
      :autoplay="autoplay"
      :loop="loop"
      :muted="muted"
      :controls="controls"
      :ratio="ratio"
      ref="videoRefs"
      :sizes="sizes"
      v-editable="element"
    />
    <SbMediaVideoAnimationTransparent
      v-else-if="element.component === 'MediaVideoAnimationTransparent'"
      :blok="element"
      :class="$attrs.class"
      :autoplay="true"
      :loop="true"
      :controls="false"
      :ratio="ratio"
      :sizes="sizes"
      v-editable="element"
    />
  </template>
</template>

<script setup lang="ts">
import {
  MediaImageStoryblok,
  MediaVideoStoryblok,
  MediaVideoAnimationTransparentStoryblok,
} from '@/types';
import { ImgRatio, ImgSizes } from '@/components/atoms/AImage.vue';
import SbMediaVideo from '@/components/sb-media/SbMediaVideo.vue';

interface Props {
  bloks: (
    | MediaImageStoryblok
    | MediaVideoStoryblok
    | MediaVideoAnimationTransparentStoryblok
  )[];
  ratio: ImgRatio;
  sizes?: ImgSizes;
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  loading?: 'lazy' | 'eager';
}

withDefaults(defineProps<Props>(), {
  controls: true,
  autoplay: false,
  loop: false,
  loading: 'lazy',
});

const videoRefs = ref<InstanceType<typeof SbMediaVideo>[] | null>(null);

const play = () => videoRefs.value?.forEach((video) => video.play());
const pause = () => videoRefs.value?.forEach((video) => video.pause());

defineExpose({
  play,
  pause,
});
</script>
