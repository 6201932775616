import { definePreset } from '@unocss/core';
// import type { CSSObject } from 'unocss';

export const breakpoints = {
  /** mobile-portrait-small */
  mps: '320px',
  /** mobile-portrait-medium */
  mpm: '375px',
  /** mobile-portrait-large */
  mpl: '414px',
  /** mobile-landscape */
  ml: '576px',
  /** tablet-portrait */
  tp: '768px',
  /** tablet-landscape */
  tl: '1024px',
  /** desktop-small */
  ds: '1280px',
  /** desktop-medium */
  nav: '1360px',
  /** desktop-large */
  dl: '1440px',
  /** desktop-xlarge */
  dxl: '1920px',
  /** widescreen */
  ws: '2560px',
} as const;

/**
 * Get breakpoint value in pixels
 */
export const getBreakpoint = (bp: keyof typeof breakpoints) => parseInt(breakpoints[bp].split('px')[0])

const presetTheme = definePreset(() => {
  return {
    name: 'preset-theme',
    theme: {
      fontFamily: {
        display: 'var(--ff-display)',
        headline: 'var(--ff-headline)',
        body: 'var(--ff-body)',
      },
      breakpoints,
    },
    rules: [
      [
        'text-display-md-stroke',
        {
          '-webkit-text-stroke-width': 'var(--sw-display-md)',
          '-webkit-text-stroke-color': 'white',
          'paint-order': 'fill stroke', // make Safari follow kinda same behavior as other browsers
        },
      ]
    ],
    shortcuts: {
      'text-display-md':
        'font-display font-size-[--fz-display-md] line-height-[--lh-display-md]',
      'text-headline-lg':
        'font-headline font-size-[--fz-headline-lg] line-height-[--lh-headline-lg]',
      'text-headline-md':
        'font-headline font-size-[--fz-headline-md] line-height-[--lh-headline-md]',
      'text-headline-sm':
        'font-headline font-size-[--fz-headline-sm] line-height-[--lh-headline-sm]',
      'text-body-lg':
        'font-body font-size-[--fz-body-lg] line-height-[--lh-body-lg]',
      'text-body-md':
        'font-body font-size-[--fz-body-md] line-height-[--lh-body-md]',
      'text-body-sm':
        'font-body font-size-[--fz-body-sm] line-height-[--lh-body-sm]',
      'text-body-xs':
        'font-body font-size-[--fz-body-xs] line-height-[--lh-body-xs]',
    },
  };
});

export { presetTheme as default, presetTheme };
