<template>
  <div class="rich-text">
    <sb-richtext :text="richtext" />
  </div>
  <!-- <div class="rich-text" v-html="rendered"></div> -->
</template>

<script setup lang="ts">
import { ISbRichtext } from '@storyblok/vue';
// import { useRichtextRender } from '@/composables/useRichtextRender';

export interface Props {
  richtext: ISbRichtext;
}
const props = defineProps<Props>();

const richtext = computed(() => props.richtext); // make reactive for the Storyblok editor
// const { rendered } = useRichtextRender(richtext);
</script>
