/*
How to use:

Add "data-scroll" to the element you want to animate
It'll add a class "in-view" when the element is in view and the var(--scroll-position) on the entire scroll where the element is in view

* All distance and offset values are in percentages of screen height from 0 to 1.

Properties:
data-scroll-distance-enter: 0 to 1 [defualt: 0] use the var(--scroll-position-enter) to animate the element when it enters the view
data-scroll-distance-exit: 0 to 1 [defualt: 0] use the var(--scroll-position-exit) to animate the element when it exits the view
data-scroll-offset-start: 0 to 1 [defualt: 0] use the var(--scroll-position) to animate the element when it's in view with the offset from the top
data-scroll-offset-end: 0 to 1 [defualt: 0] use the var(--scroll-position) to animate the element when it's in view with the offset from the bottom
data-scroll-distance-custom: 0 to 1 [defualt: 0] use the var(--scroll-position-custom) to animate the element with a custom scroll distance it'll use same offset as the default scroll
data-scroll-debug-msg: true or false [defualt: false] will show the scroll position in the element and log errors in the console

Css example:
The example below will animate the opacity from 50% to 100% when it's in view

.example {
  opacity: calc(0.5 + (var(--scroll-position-enter) * 0.5));
}
*/

import { useScrollDirection } from "./helpers/useScrollDirection.ts";
import { useScrollFull } from "./helpers/useScrollFull.ts";
import { useScrollEnter } from "./helpers/useScrollEnter.ts";
import { useScrollExit } from "./helpers/useScrollExit.ts";
import { useScrollScreen } from "./helpers/useScrollScreen.ts";
import { createScrollDebugMsgContainer } from "./helpers/useScrollDebugMsg.ts";
import { OptionRoot } from "./types";

// Globla variables
let lastScrollTop: number = 0;
let isFirstEvent: boolean = true;

const scrollListener = (hasDebug: boolean, eventCallback: Function) => {
  const onScroll = () => {
    const documentScrollTop: number = document.documentElement.scrollTop;

    if (documentScrollTop === lastScrollTop && !isFirstEvent) {
      requestAnimationFrame(onScroll);
      return true;
    }

    ////////////////////////////////////
    // Scroll direction               //
    // "/types/useScrollDirection.ts" //
    ////////////////////////////////////

    const scrollDirection = useScrollDirection(
      documentScrollTop,
      lastScrollTop
    );

    // Update lastScrollTop
    lastScrollTop = scrollDirection;

    /////////////////////////////////////////////////
    // Loop through all elements with data-scroll ///
    /////////////////////////////////////////////////

    const divs = document.querySelectorAll(
      "[data-many-scroller]"
    ) as NodeListOf<HTMLElement>;
    divs.forEach((div: HTMLElement) => {
      const optionsAttr = div.getAttribute("data-many-scroller");
      const options: OptionRoot = optionsAttr ? JSON.parse(optionsAttr) : false;
      const optionsFull = options.full;
      const optionsScreen = options.screen;
      const optionsEnter = options.enter;
      const optionsExit = options.exit;
      const optionsBreakpoints = options.breakpoints || [320];
      const rect = div.getBoundingClientRect();
      const top = rect.top;
      const windowHeight = window.innerHeight;
      let windowScrollY = window.scrollY;
      let elementOffsetTop = top + windowScrollY;

      /////////////////////////////////////////
      // Full Scroll                         //
      // var(--scroll-position-full)         //
      // Amount: window height + rect height //
      /////////////////////////////////////////

      if (optionsFull) {
        useScrollFull(
          optionsFull,
          div,
          rect,
          top,
          windowHeight,
          optionsBreakpoints,
          elementOffsetTop,
          eventCallback,
          hasDebug
        );
      }

      //////////////////////////////////////
      // Custom Scroll                    //
      // "/types/useScrollScreen.ts"      //
      // var(--scroll-position-screen)    //
      // Amount: window height * distance //
      //////////////////////////////////////

      if (optionsScreen) {
        useScrollScreen(
          optionsScreen,
          div,
          top,
          windowHeight,
          optionsBreakpoints,
          elementOffsetTop,
          eventCallback,
          hasDebug
        );
      }

      //////////////////////////////////////
      // Scroll Enter                     //
      // "/types/useScrollEnter.ts"       //
      // var(--scroll-position-enter)     //
      // Amount: window height * distance //
      //////////////////////////////////////

      if (optionsEnter) {
        useScrollEnter(
          optionsEnter,
          div,
          top,
          windowHeight,
          optionsBreakpoints,
          elementOffsetTop,
          eventCallback,
          hasDebug
        );
      }
      //////////////////////////////////////
      // Scroll Exit                      //
      // "/types/useScrollExit.ts"        //
      // var(--scroll-position-exit)      //
      // Amount: window height * distance //
      //////////////////////////////////////

      if (optionsExit) {
        useScrollExit(
          optionsExit,
          div,
          rect,
          top,
          windowHeight,
          optionsBreakpoints,
          elementOffsetTop,
          eventCallback,
          hasDebug
        );
      }

      ///////////////////////////////////
      // Create Debug Messenge         //
      // "/types/useScrollDebugMsg.ts" //
      ///////////////////////////////////

      if (hasDebug) {
        createScrollDebugMsgContainer(div);
      }

      /////////////////////////////////
      // Default Scroll - is in view //
      // Add/remove "in-view" class  //
      // Call custom function        //
      /////////////////////////////////
      isFirstEvent = false;
    });

    requestAnimationFrame(onScroll);
  };

  requestAnimationFrame(onScroll);

  const destroy = () => {
    cancelAnimationFrame((onScroll as unknown) as number);
  };

  return { destroy };
};

const initRootVariables = () => {
  // Select the root element
  //const root = document.documentElement;
  // Add or modify the --data-scroll-position property
  // root.style.setProperty("--data-scroll-full", "0");
  // root.style.setProperty("--data-scroll-enter", "0");
  // root.style.setProperty("--data-scroll-exit", "0");
  // root.style.setProperty("--data-scroll-screen", "0");
};

const useManyScroll = (
  destroy: boolean,
  hasDebug: boolean,
  eventCallback: Function
) => {
  const scrollInstance = scrollListener(hasDebug, eventCallback);
  if (destroy) {
    scrollInstance.destroy();
  }
};

class WManyScroll extends HTMLElement {
  private hasDebug: boolean;

  constructor() {
    super();
    this.hasDebug = this.getAttribute("debug") === "true";
    this.eventCallback = this.eventCallback.bind(this);
  }
  eventCallback(event: CustomEvent): void {
    this.dispatchEvent(event);
  }
  connectedCallback(): void {
    initRootVariables();
    useManyScroll(false, this.hasDebug, this.eventCallback);
  }
  disconnectedCallback(): void {
    useManyScroll(true, this.hasDebug, this.eventCallback);
  }
}

customElements.define("w-many-scroller", WManyScroll);
