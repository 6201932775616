<template>
  <div
    ref="wrapRef"
    :class="[
      'w-full',
      'h-full',
      // 'border-solid border-10 border-red',
    ]"
  >
    <video
      v-if="visible"
      :class="[
        'w-full h-full object-contain transition-opacity duration-400',
        {
          'opacity-0': !loaded,
        },
      ]"
      ref="videoRef"
      :controls="controls"
      :autoplay="autoplay"
      :muted="autoplay"
      :loop="loop"
      controlslist="nodownload noremoteplayback nofullscreen"
      disablepictureinpicture
      playsinline
      @canplay="
        loaded = true;
        emit('canPlay');
      "
      @play="emit('play', $event)"
      @pause="emit('pause', $event)"
      @ended="emit('ended', $event)"
    >
      <source :src="videoMp4Url" type='video/mp4; codecs="hvc1"' />
      <source :src="videoWebmUrl" type="video/webm" />
    </video>
  </div>
</template>

<script setup lang="ts">
interface Props {
  videoMp4Url: string;
  videoWebmUrl: string;
  controls?: boolean;
  autoplay?: boolean;
  loop?: boolean;
}
withDefaults(defineProps<Props>(), {
  controls: true,
  autoplay: false,
  loop: false,
});

const emit = defineEmits<{
  play: [event: Event];
  pause: [event: Event];
  ended: [event: Event];
  canPlay: [];
}>();

// lazy load video
const wrapRef = ref<HTMLElement>();
const videoRef = ref<HTMLVideoElement>();
const visible = ref<boolean>(false);
const loaded = ref<boolean>(false);
const intersectionOptions: IntersectionObserverInit = {
  root: null,
  rootMargin: '500px', // load 500px before it come into view
  threshold: 0.1,
};
let observer: IntersectionObserver;

const onObserve = (entries: IntersectionObserverEntry[]) => {
  if (entries[0].isIntersecting && wrapRef.value) {
    visible.value = true;
    observer.unobserve(wrapRef.value!);
  }
};

onMounted(() => {
  observer = new IntersectionObserver(onObserve, intersectionOptions);
  observer.observe(wrapRef.value!);
});

const play = () => videoRef.value!.play();
const pause = () => videoRef.value!.pause();
defineExpose({
  play,
  pause,
});
</script>
