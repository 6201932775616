<template>
  <span
    :class="[
      `inline-block ${iconClass}`,
      {
        'clamp-w-4-4 clamp-h-4-4': size === 'xs', // 16px
        'clamp-w-5-5 clamp-h-5-5': size === 'sm', // 24px
        'clamp-w-6-6 clamp-h-6-6': size === 'md', // 32px
      },
    ]"
  ></span>
</template>

<script setup lang="ts">
import { Fa6SolidIcon } from '@/types';

interface Props {
  icon: Fa6SolidIcon;
  size?: null | 'xs' | 'sm' | 'md'; // tip: parse null as size prop to use custom clamp class in parent component
}

const props = withDefaults(defineProps<Props>(), {
  size: 'sm',
});

const iconClass = computed(() => {
  if (props.icon.startsWith('custom-')) return `i-${props.icon}`;
  return `i-fa6-solid-${props.icon}`;
});
</script>
