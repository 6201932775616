<template>
  <component
    v-for="item in blok.attrs.body"
    :key="item.id"
    :is="item.component"
    :blok="item"
  />
</template>

<script>
export default {
  name: "SbBlok",
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
