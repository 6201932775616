////////////////////////
// Handle breakpoints //
////////////////////////

export const useBreakpoints = (breakpoints: number[], values: number[]) => {
  let closestValue = values[0];
  if (breakpoints.length > 1) {
    const windowWidth = window.innerWidth;
    let closestBreakpoint = breakpoints[0];
    let lastAvailableValue = values[0];

    for (let i = 1; i < breakpoints.length; i++) {
      if (
        Math.abs(windowWidth - breakpoints[i]) <
          Math.abs(windowWidth - closestBreakpoint) &&
        values[i] !== undefined
      ) {
        closestBreakpoint = breakpoints[i];
        closestValue = values[i];
        lastAvailableValue = values[i];
      } else {
        closestValue = lastAvailableValue;
      }
    }
  }
  return closestValue;
};
