<template>
  <span v-if="isStoryblokVisualEditor">
    <!-- disable links in Storyblok to prevent navigate when clicking elements in the visual editor -->
    <slot />
  </span>
  <router-link
    v-else-if="to.linktype === 'story' && to.cached_url"
    :to="internalUrl"
  >
    <slot />
  </router-link>
  <a
    v-else-if="to.linktype === 'email' && to.email"
    :href="'mailto:' + to.email"
    :target="to.target"
  >
    <slot />
  </a>
  <a
    v-else-if="to.linktype === 'tel' && to.tel"
    :href="'tel:' + to.tel"
    :target="to.target"
  >
    <slot />
  </a>
  <a v-else-if="externalUrl" :href="externalUrl" :target="to.target">
    <slot />
  </a>
</template>

<script setup lang="ts">
import { MultilinkStoryblok } from '@/types';
import useIsStoryblok from '@/composables/useIsStoryblok';
const { isStoryblokVisualEditor } = useIsStoryblok();

type Tel = {
  linktype?: 'tel';
  tel?: string;
  [k: string]: any;
};

interface Props {
  to: MultilinkStoryblok | Tel;
}
const props = defineProps<Props>();

const internalUrl = computed(() => {
  if (props.to.linktype === 'story') {
    let link = props.to.cached_url;
    if (import.meta.env.MODE.includes('editor')) {
      link = link?.startsWith('/') ? link : '/' + link;
    } else {
      link = link?.replace(import.meta.env.VITE_STORYBLOK_BRAND_FOLDER, '');
      link = link?.startsWith('/') ? link : '/' + link;
    }
    return link;
  }
  return '';
});

const externalUrl = computed(() => {
  if (props.to.linktype === 'url') {
    if (props.to.url?.startsWith('www.')) {
      return 'https://' + props.to.url;
    }
  }
  return props.to.url;
});
</script>
