//////////////////////////////////////
// Handle inview and event triggers //
//////////////////////////////////////
import { ScrollerType, EventType, OptionType } from "./../types";

export const useInview = (
  div: HTMLElement,
  eventScrollerType: EventType[] | undefined, // fires events
  position: number,
  domScrollerType: ScrollerType, // sets class
  eventCallback: Function,
  eventOptionType: OptionType
) => {
  // Use event
  const eventIDAttr = div.getAttribute("data-many-scroller-event-id");

  // Handle inview types
  if (position > 0 && position < 1) {
    // Add inview class
    div.classList.add("inview-" + eventOptionType);
  } else {
    if (domScrollerType != "inview-once") {
      div.classList.remove("inview-" + eventOptionType);
    }
  }

  // EVENTS

  if (eventScrollerType && eventScrollerType?.length > 0) {
    eventScrollerType.forEach((event) => {
      // check if event is fired
      const eventFired =
        div.getAttribute(`data-many-scroller-${eventOptionType}-${event}`) ||
        false;

      let eventID = "";

      if (position > 0 && position < 1) {
        if (eventIDAttr) {
          eventID = eventIDAttr + "-" + eventOptionType + "-" + event;

          if (!eventFired) {
            div.setAttribute(
              `data-many-scroller-${eventOptionType}-${event}`,
              "false"
            );
          }
        }

        switch (event) {
          case "progress":
            eventCallback(
              new CustomEvent(eventID, {
                detail: position,
                bubbles: true,
                composed: true,
              })
            );
            break;
          case "enter":
          case "enter-once":
            if (eventFired === "true") break;
            eventCallback(
              new CustomEvent(eventID, {
                detail: position,
                bubbles: true,
                composed: true,
              })
            );
            div.setAttribute(
              `data-many-scroller-${eventOptionType}-${event}`,
              "true"
            );

          case "exit":
          case "exit-once":
            if (eventFired === "true") break;
            div.setAttribute(
              `data-many-scroller-${eventOptionType}-${event}`,
              "true"
            );
        }

        // if (event === "progress") {
        //   eventCallback(
        //     new CustomEvent(eventID, {
        //       detail: position,
        //       bubbles: true,
        //       composed: true,
        //     })
        //   );
        // }
        // Fire inview event
        // if (event != "progress" && eventFired === "false") {
        //   if (event === "enter-once" || event === "enter") {
        //     eventCallback(
        //       new CustomEvent(eventID, {
        //         detail: position,
        //         bubbles: true,
        //         composed: true,
        //       })
        //     );
        //   }
        //   div.setAttribute(
        //     "data-many-scroller-event-fired-" + eventOptionType + "-" + event,
        //     "true"
        //   );
        // }
      } else {
        // Fire inview event
        if (event) {
          eventID = eventIDAttr + "-" + eventOptionType + "-" + event;
          const exitOnceFired = div.getAttribute(
            `data-many-scroller-${eventOptionType}-${event}-fired`
          );
          if (
            event == "exit-once" &&
            eventFired === "true" &&
            exitOnceFired != "true"
          ) {
            eventCallback(
              new CustomEvent(eventID, {
                detail: position,
                bubbles: true,
                composed: true,
              })
            );

            div.setAttribute(
              `data-many-scroller-${eventOptionType}-${event}`,
              "false"
            );

            div.setAttribute(
              `data-many-scroller-${eventOptionType}-${event}-fired`,
              "true"
            );
          }

          if (event == "exit" && eventFired === "true") {
            eventCallback(
              new CustomEvent(eventID, {
                detail: position,
                bubbles: true,
                composed: true,
              })
            );
            div.setAttribute(
              `data-many-scroller-${eventOptionType}-${event}`,
              "false"
            );
          }

          if (
            (event == "enter" && eventFired === "true") ||
            (event == "progress" && eventFired === "true")
          ) {
            div.setAttribute(
              `data-many-scroller-${eventOptionType}-${event}`,
              "false"
            );
          }
        }
      }
    });
  }
};
