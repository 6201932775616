import { OptionRoot } from '../webcomponents/manyScroller/types';

////////////////////////
// Handle breakpoints //
////////////////////////

// const scrollOptionsAllObj = {
//   breakpoints: [320, 768, 1260],
//   full: {
//     type: 'progress', // inview-once, inview-always, progress, default[inview-once]
//     offsetStart: [0.5],
//     offsetEnd: [0.5],
//     event: 'progress', // inview-once, inview-always, progress, default[inview-once]
//   },
//   enter: {
//     type: 'progress', // inview-once, inview-always, progress, default[inview-once]
//     distance: [0.5, 1],
//     event: 'progress', // inview-once, inview-always, progress, default[inview-once]
//   },
//   exit: {
//     type: 'progress', // inview-once, inview-always, progress, default[inview-once]
//     distance: [0.25, 0.5],
//     event: 'progress', // inview-once, inview-always, progress, default[inview-once]
//   },
//   screen: {
//     type: 'progress', // inview-once, inview-always, progress, default[inview-once]
//     distance: [0.5],
//     offsetStart: [0],
//     event: 'progress', // inview-once, inview-always, progress, default[inview-once]
//   },
// };

const progressObj: OptionRoot = {
  full: {
    type: 'progress',
  },
};

const timelineObj: OptionRoot = {
  full: {
    type: 'progress',
    offsetStart: [1],
    offsetEnd: [1],
  },
};

const sectionEntryObj: OptionRoot = {
  breakpoints: [320, 1024],
  enter: {
    type: 'progress',
    distance: [0.3, 0.5],
  },
};
const footerScrollerObj: OptionRoot = {
  full: {
    type: 'inview-always',
    offsetStart: [0.5],
    offsetEnd: [0],
    event: ['enter', 'exit'],
  },
};

const inviewAlwaysEventObj: OptionRoot = {
  full: {
    type: 'inview-always',
    event: ['enter', 'exit'],
  },
};
const modalInviewOnceObj: OptionRoot = {
  full: {
    type: 'inview-once',
    event: ['enter'],
    offsetStart: [0.5],
    offsetEnd: [0.5],
  },
};

const progress = JSON.stringify(progressObj, null, 0);
const timeline = JSON.stringify(timelineObj, null, 0);
const sectionEntry = JSON.stringify(sectionEntryObj, null, 0);
const footerScroller = JSON.stringify(footerScrollerObj, null, 0);
const inviewAlwaysEvent = JSON.stringify(inviewAlwaysEventObj, null, 0);
const modalInviewOnce = JSON.stringify(modalInviewOnceObj, null, 0);


export const useManyScrollerOptions = () => {
  return { progress, timeline, sectionEntry, footerScroller, inviewAlwaysEvent, modalInviewOnce };
};
