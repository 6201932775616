<template>
  <div
    :class="[
      `fixed top-0 bottom-0 left-0 right-0 h-100vh z-header`,
      `bg-[--bg-color] text-white`,
      `before:fixed before:top-0 before:content-[''] before:w-full before:clamp-h-32-64 before:bg-gradient-to-b before:z-header-gradient before:from-[--bg-color] before:to-[--to-color]`,
    ]"
    :style="{
      '--bg-color': overlayBackgroundColor.hex,
    }"
  >
    <div
      :class="[
        `h-fill-available overflow-scroll`,
        `flex flex-col justify-between pt-header-candle clamp-pb-8-16`,
      ]"
    >
      <ElementPattern
        v-if="header?.backgroundPattern?.length"
        :bloks="header.backgroundPattern"
        :fadeOut="true"
        objectFit="cover"
        className="absolute top-0 left-0 w-full h-full z--1"
      />
      <div
        class="flex flex-col clamp-gap-6-8 justify-center items-center clamp-pt-8-16"
      >
        <BaseNavLinks
          v-if="header?.navLinks?.length"
          :nav-links="header.navLinks"
          class="text-headline-md"
          @click="$emit('close')"
          :style="{
            'font-weight': `var(--fw-headline-md)`,
          }"
        />
      </div>
      <BaseNavSoMeButtons size="lg" class="justify-center clamp-mt-8-16" />
    </div>
  </div>
</template>

<script setup lang="ts">
import useConfig from '@/composables/useConfig';
import { ISbPluginThemeColorPicker } from '@/types';

const { config } = useConfig();
const header = computed(() => config.value?.header?.[0]);

const overlayBackgroundColor = computed(
  () => header.value?.overlayBackgroundColor as ISbPluginThemeColorPicker,
);

defineEmits(['close']);
</script>
