<template>
  <u><slot /></u>
</template>

<script>
export default {
  name: "SbUnderline",
  props: {
    blok: {
      type: Object,
      required: false,
    },
  },
};
</script>
