<template>
  <Transition name="fade">
    <BaseNavOverlay v-if="navOpen" @close="closeNav" />
  </Transition>
  <header
    aria-label="Header"
    v-if="header"
    class="fixed top-0 w-full z-header"
    :class="{ 'hide-header': hideHeader }"
  >
    <SbCandleBanner class="relative z-header mt--1px" />
    <AGradientBlur
      class="absolute left-0 clamp-top-8-12 clamp-h-48-96 z-header-gradient translate-y--1px"
      direction="to bottom"
      :grayscale="true"
      :strength="0.5"
    />
    <div class="absolute w-full z-header text-white pointer-events-none">
      <nav
        class="page-mx h-header flex items-center"
        aria-label="Primary navigation"
      >
        <div class="flex flex-1 clamp-gap-8-8 items-center">
          <div
            class="hidden nav:flex nav:clamp-gap-8-8 nav:flex-row nav:items-center"
            role="menubar"
            aria-label="Main"
          >
            <BaseNavLinks
              v-if="header.navLinks"
              :nav-links="header.navLinks"
              class="text-body-md font-bold"
            />
          </div>
        </div>
        <BaseLogoBrand
          class="z-header-above"
          :animated="true"
          @click="closeNav"
        />
        <div class="flex grow-1 shrink-0 basis-0 justify-end">
          <BaseNavOverlayToggle
            v-if="header.navLinks?.length"
            :is-open="navOpen"
            class="z-header-above nav:hidden"
            @click="toggleNav"
          />
          <BaseNavSoMeButtons class="flex-1 justify-end !hidden !nav:flex" />
        </div>
      </nav>
    </div>
  </header>
</template>

<script setup lang="ts">
import useConfig from '@/composables/useConfig';
import { useScrollLock, useWindowSize } from '@vueuse/core';
import { getBreakpoint } from '@/../uno-preset/preset-theme';
import { useHeader } from '@/composables/useHeader';

const { config } = useConfig();
const { width: windowWidth } = useWindowSize();
const { hideHeader } = useHeader();

const header = computed(() => config.value?.header[0]);

const el = ref<HTMLElement | null>(null);
const isLocked = useScrollLock(el);
const navOpen = ref(false);

const openNav = () => {
  navOpen.value = true;
  isLocked.value = true;
};
const closeNav = () => {
  navOpen.value = false;
  isLocked.value = false;
};
const toggleNav = () => {
  if (navOpen.value) closeNav();
  else openNav();
};

watch(windowWidth, (newWidth) => {
  if (newWidth >= getBreakpoint('nav')) closeNav();
});

onMounted(() => {
  el.value = document.querySelector('body');
});
</script>

<style lang="scss">
// animation: navigation bar direction hide/show
html[data-scrolldir='down'] {
  header {
    transform: translateY(-100%);
  }
}

header {
  @apply transition-transform duration-500 ease-out;
  &.hide-header {
    // the header content is 350% of the height of actual <header> thus we need to move it up by 350%
    transform: translateY(-350%) !important;
  }
}
</style>
