<template>
  <div
    role="menubar"
    class="flex-1 flex clamp-gap-3-5 justify-center ml:justify-start flex-wrap"
  >
    <ALink
      role="menuitem"
      v-for="item in buttons"
      :key="item._uid"
      :to="item.link"
    >
      <AButton :icon="getTypedIcon(item.icon)" size="sm" :text="item.label" />
    </ALink>
  </div>
</template>

<script setup lang="ts">
import { ElementButtonStoryblok, Fa6SolidIcon } from '@/types';

interface Props {
  buttons: ElementButtonStoryblok[];
}
defineProps<Props>();

const getTypedIcon = (icon: string | number | undefined) =>
  icon as Fa6SolidIcon | undefined;
</script>
