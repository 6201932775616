const hideHeader = ref(false);
export function useHeader(): {
  hideHeader: Ref<boolean>;
  setHideHeader: (value: boolean) => void;
} {
  function setHideHeader(value: boolean) {
    hideHeader.value = value;
  }

  return {
    hideHeader,
    setHideHeader,
  };
}
