import SbRichtext from "./sb-richtext.vue";
export default {
  install: (app, options) => {
    app.component("sb-richtext", SbRichtext);
    const blocks = import.meta.globEager('./blocks/*.vue');
    const marks = import.meta.globEager('./marks/*.vue');

    Object.entries({ ...blocks, ...marks }).forEach(([path, definition]) => {
      const componentName = path.split('/').pop().replace(/\.\w+$/, '')
      app.component(componentName, definition.default)
    })
  },
};