<template>
  <img
    ref="imageRef"
    :src="visible ? startSrc : undefined"
    :srcset="visible ? srcSet : undefined"
    :sizes="sizes"
    :alt="alt"
    :title="title"
    :loading="loading"
    :width="imgRatioSize.w"
    :height="imgRatioSize.h"
    :fetchpriority="loading === 'eager' ? 'high' : 'auto'"
    :class="[
      'block scale-100',
      { 'is-loaded': loaded },
      { 'is-visible': visible },
      { 'opacity-0': loading !== 'eager' },
    ]"
    @load="loaded = true"
  />
</template>

<script setup lang="ts">
// Info about lazy loading and when to: https://web.dev/browser-level-image-lazy-loading/
import { breakpoints, getBreakpoint } from '@/../uno-preset/preset-theme';

export type ImgSizes = { bpMin: '0' | keyof typeof breakpoints; vw: number }[];
export type ImgRatio = 'orig' | { w: number; h: number };

export type Props = {
  src: string;
  ratio: ImgRatio;
  loading?: 'lazy' | 'eager';
  sizes?: ImgSizes;
  focalPoint: string | undefined;
  alt?: string;
  title?: string;
};

const props = withDefaults(defineProps<Props>(), {
  loading: 'lazy',
});

const crops = [96, 160, 320, 480, 768, 990, 1024, 1280, 1440, 1920, 2560];
const imgPath = computed(() =>
  props.src.replace('https://a.storyblok.com/', ''),
);
const imgService = 'https://img2.storyblok.com/';

const imgOptions = computed(() => {
  if (props.focalPoint)
    return `/filters:format(webp):focal(${props.focalPoint})/`;
  return '/smart/filters:format(webp)/';
});

const imgRatioSize = computed(() => {
  let ratioW = 16;
  let ratioH = 9;
  if (props.ratio === 'orig') {
    const getWH = imgPath.value.split('/')[2];
    ratioW = Number(getWH.split('x')[0]);
    ratioH = Number(getWH.split('x')[1]);
  } else {
    ratioW = props.ratio.w;
    ratioH = props.ratio.h;
  }
  return {
    ratio: ratioH / ratioW,
    w: ratioW,
    h: ratioH,
  };
});

const srcSet = computed(() => {
  if (props.src.includes('.svg')) {
    return;
  }
  return crops
    .map((w) => {
      let h = Math.round(imgRatioSize.value.ratio * w);
      if (props.ratio == 'orig') h = 0; // setting to 0 keeps the original ratio
      return `${imgService}${w}x${h}${imgOptions.value}${imgPath.value} ${w}w`;
    })
    .join(',');
});

const startSrc = computed(() => {
  if (props.src.includes('.svg')) {
    return props.src;
  } else if (props.ratio == 'orig') {
    return props.src;
  }
  return `${imgService}${100}x${Math.round(imgRatioSize.value.ratio * 100)}${
    imgOptions.value
  }${imgPath.value}`;
});

const sizes = computed(() => {
  if (!props.sizes) return '100vw';

  // sort sizes by bpMin (largest bpMin first) - otherwise the browser will use the first size that matches
  props.sizes.sort((a, b) => {
    if (a.bpMin === '0') return 1;
    if (b.bpMin === '0') return -1;
    const bBpMin = getBreakpoint(b.bpMin);
    const aBpMin = getBreakpoint(a.bpMin);
    return bBpMin - aBpMin;
  });

  // return sizes string
  return props.sizes
    .map((size) => {
      if (size.bpMin === '0') return `${size.vw}vw`;
      return `(min-width: ${breakpoints[size.bpMin]}) ${size.vw}vw`;
    })
    .join(', ');
});

const imageRef = ref<HTMLElement | null>(null);
const visible = ref<boolean>(false);
const loaded = ref<boolean>(false);

const intersectionOptions: IntersectionObserverInit = {
  root: null,
  rootMargin: '500px', // load 500px before it come into view
  threshold: 0.1,
};
const intersectionObserver = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      // Load the image when it comes into view
      visible.value = true;
      // Stop observing once the image is loaded
      observer.unobserve(imageRef.value!);
    }
  });
}, intersectionOptions);

onMounted(() => {
  if (props.loading === 'eager') {
    visible.value = true;
    return;
  }

  intersectionObserver.observe(imageRef.value!);
});
</script>

<style scoped lang="scss">
.is-visible.is-loaded {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
</style>
