<template>
  <router-link
    v-if="getLink.linktype === 'story' && getLink.cached_url"
    :to="resolveSlug"
  >
    <slot />
  </router-link>
  <a
    :href="'mailto:' + getLink.email"
    :target="getLink.target"
    v-else-if="getLink.linktype === 'email' && getLink.email"
  >
    <slot />
  </a>
  <a
    :href="getLink.asset"
    v-else-if="getLink.linktype === 'asset' && getLink.asset"
    :target="getLink.target"
  >
    <slot
  /></a>
  <a :href="getLink.url" v-else-if="getLink.url" :target="getLink.target">
    <slot />
  </a>
</template>
<script>
//.startsWith('http') ? getLink.url : 'https://' + getLink.url
export default {
  name: 'SbLink',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getLink() {
      const linktype = this.blok.attrs.linktype;
      const href = this.blok.attrs.href;
      const target = this.blok.attrs.target;

      let link = {};
      if (linktype === 'story') {
        link['linktype'] = linktype;
        link['cached_url'] = href;
      } else if (linktype === 'email') {
        link['linktype'] = linktype;
        link['email'] = href;
        link['target'] = target;
      } else if (linktype === 'asset') {
        link['linktype'] = linktype;
        link['asset'] = href;
        link['target'] = '_blank';
      } else {
        link['linktype'] = 'url';
        link['url'] = href.startsWith('http') ? href : 'https://' + href;
        link['target'] = '_blank';
      }
      return link;
    },
    resolveSlug() {
      if (!this.getLink.linktype === 'story') return true;
      let slug = this.getLink.cached_url;
      if (slug === '/home') {
        slug = '/';
      }
      return slug;
    },
  },
};
</script>
