//////////////////////////////////////
// Scroll Enter                     //
// var(--scroll-position-enter)     //
// Amount: window height * distance //
//////////////////////////////////////

import { useScrollDebugMsg } from './useScrollDebugMsg.js';
import { useInview } from './handleInview.js';
import { useBreakpoints } from './handleBreakpoints.js';
import { OptionScreen } from './../types';

export const useScrollScreen = (
  options: OptionScreen,
  div: HTMLElement,
  top: number,
  windowHeight: number,
  optionsBreakpoints: number[],
  elementOffsetTop: number,
  eventCallback: Function,
  hasDebug: boolean,
) => {
  // Use offsets
  let getOffsetStart = options.offsetStart || false;
  let getScrollDistance = options.distance || false;
  let getScrollerEventType = options.event || undefined;
  let scrollerType = options.type || 'inview-once';
  let offsetDistanceStart = 0;
  let scrollDistance = 0;

  // Remove offsets
  if (windowHeight > elementOffsetTop) {
    // In first view
  } else {
    if (getOffsetStart) {
      offsetDistanceStart = useBreakpoints(optionsBreakpoints, getOffsetStart);
    }
    if (getScrollDistance) {
      scrollDistance = useBreakpoints(optionsBreakpoints, getScrollDistance);
    }
  }

  let position = 0;
  const customScrollStart = windowHeight * offsetDistanceStart;
  const customScrollDistance = windowHeight * scrollDistance;

  if (offsetDistanceStart >= 0) {
    if (top <= customScrollStart) {
      if (top >= customScrollStart - customScrollDistance) {
        position = Math.abs(
          (1 / customScrollDistance) * (top - customScrollStart),
        );
      } else if (customScrollStart - customScrollDistance > top) {
        position = 1;
      } else {
        position = 0;
      }
    }
  }

  // Set position as css variable
  position = Math.round(position * 10000) / 10000;
  if (scrollerType === 'progress') {
    (div as HTMLElement).style.setProperty(
      '--scroll-position-screen',
      position.toString(),
    );
  }

  // Use inview - handles inview class and events
  useInview(
    div,
    getScrollerEventType,
    position,
    scrollerType,
    eventCallback,
    'screen',
  );

  // Show debug
  if (hasDebug) {
    // Progress debug
    if (scrollerType === 'progress' && scrollDistance > 0) {
      useScrollDebugMsg(div, position, 'Screen', 'primary');
    }
    // Offset start debug
    if (offsetDistanceStart > 0) {
      useScrollDebugMsg(
        div,
        offsetDistanceStart,
        'Offset-start-screen',
        'secondary',
      );
    }
  }
};
