<template>
  <video
    ref="videoRef"
    :controls="controls"
    :autoplay="autoplay"
    :muted="autoplay ? true : muted"
    :loop="loop"
    controlslist="nodownload noremoteplayback nofullscreen"
    disablepictureinpicture
    playsinline
    :class="[
      $attrs.class,
      'block scale-100',
      { 'opacity-0': true },
      { 'is-loaded': loaded },
      { 'is-visible': visible },
    ]"
    @play="emit('play', $event)"
    @pause="emit('pause', $event)"
    @ended="emit('ended', $event)"
  />
</template>

<script setup lang="ts">
import Hls from 'hls.js';

interface Props {
  hlsPlaylistUrl: string;
  loading?: 'lazy' | 'eager';
  controls?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  muted?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  loading: 'lazy',
  controls: true,
  autoplay: false,
  loop: false,
});

const emit = defineEmits<{
  play: [event: Event];
  pause: [event: Event];
  ended: [event: Event];
  canPlay: [];
}>();

const videoRef = ref<HTMLVideoElement | null>(null);
const visible = ref(false);
const loaded = ref(false);

const intersectionOptions: IntersectionObserverInit = {
  root: null,
  rootMargin: '500px', // load 500px before it come into view
  threshold: 0.1,
};
const intersectionObserver = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      visible.value = true;
      if (videoRef.value) {
        initHlsPlayer();
        observer.unobserve(videoRef.value);
      }
    }
  });
}, intersectionOptions);

onMounted(() => {
  if (props.loading === 'eager') {
    visible.value = true;
    initHlsPlayer();
    return;
  }

  if (videoRef.value) intersectionObserver.observe(videoRef.value);
});

onUnmounted(() => {
  intersectionObserver.disconnect();
});

// for debugging
// const onTimeUpdate = () => {
//   if (videoRef.value) {
//     const videoId = props.hlsPlaylistUrl
//       .split('cloudflarestream.com/')[1]
//       .split('/manifest')[0];
//     console.log('timeupdate', videoId, videoRef.value.currentTime);
//   }
// };

const initHlsPlayer = () => {
  if (!videoRef.value) return;

  const videoSrc = props.hlsPlaylistUrl;

  if (Hls.isSupported()) {
    // hls.js is supported
    var hls = new Hls({
      debug: false,
      startLevel: 10,
    });
    hls.loadSource(videoSrc);
    hls.attachMedia(videoRef.value);
    hls.subtitleDisplay = true;
    hls.once(Hls.Events.BUFFER_APPENDED, function () {
      // console.log('BUFFER_APPENDED');
      loaded.value = true;
      emit('canPlay');
    });
  } else if (videoRef.value.canPlayType('application/vnd.apple.mpegurl')) {
    // native HLS is supported
    videoRef.value.src = videoSrc;
    videoRef.value.addEventListener('loadedmetadata', function () {
      loaded.value = true;
      emit('canPlay');
    });
  }
};

const play = () => videoRef.value!.play();
const pause = () => videoRef.value!.pause();
defineExpose({
  play,
  pause,
});
</script>

<style scoped lang="scss">
.is-visible.is-loaded {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
</style>
