const isStoryblok = ref(false);
const isStoryblokVisualEditor = ref(false);

export default function useIsStoryblok() {
  const checkIfStoryblokEditor = () => {
    let parentSlug = window.location.href;
    if (window.location.ancestorOrigins) {
      for (let i = 0; i < window.location.ancestorOrigins.length; i++) {
        const origin = window.location.ancestorOrigins[i];
        if (origin.includes('app.storyblok')) {
          parentSlug = origin;
        }
      }
    }
    isStoryblok.value =
      parentSlug.includes('app.storyblok') ||
      parentSlug.includes('_storyblok=');
    isStoryblokVisualEditor.value = parentSlug.includes('app.storyblok');
  };

  onMounted(() => {
    checkIfStoryblokEditor();
  });

  return {
    isStoryblok,
    isStoryblokVisualEditor,
  };
}
