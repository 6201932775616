<template>
  <component :blok="marks[0]" :is="marks[0].type">
    <template v-if="marks.length === 1">
      <component :blok="blok" :is="blok.type" />
    </template>

    <component
      :blok="marks[1]"
      :is="marks[1].type"
      v-else-if="marks.length > 1"
    >
      <template v-if="marks.length === 2">
        <component :blok="blok" :is="blok.type" />
      </template>

      <component
        :blok="marks[2]"
        :is="marks[2].type"
        v-else-if="marks.length > 2"
      >
        <template v-if="marks.length === 3">
          <component :blok="blok" :is="blok.type" />
        </template>

        <component
          :blok="marks[3]"
          :is="marks[3].type"
          v-else-if="marks.length > 3"
        >
          <template v-if="marks.length === 4">
            <component :blok="blok" :is="blok.type" />
          </template>
          <component
            :blok="marks[4]"
            :is="marks[4].type"
            v-else-if="marks.length > 4"
          >
            <template v-if="marks.length === 5">
              <component :blok="blok" :is="blok.type" />
            </template>
          </component>
        </component>
      </component>
    </component>
  </component>
</template>

<script>
export default {
  props: {
    marks: {
      type: Array,
      default: () => []
    },
    blok: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
