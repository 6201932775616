///////////////////////////////////////
// Scroll direction - set on <html> ///
///////////////////////////////////////

const SCROLL_THRESHOLD = 10;

export const useScrollDirection = (
  scrollTop: number,
  lastScrollTop: number,
) => {
  const doc = document.documentElement;
  const scrolldir = 'data-scrolldir';

  if (scrollTop > 300) {
    if (scrollTop > lastScrollTop + SCROLL_THRESHOLD) {
      doc.setAttribute(scrolldir, 'down');
      lastScrollTop = scrollTop;
    } else if (scrollTop < lastScrollTop - SCROLL_THRESHOLD) {
      doc.setAttribute(scrolldir, 'up');
      lastScrollTop = scrollTop;
    }
  } else {
    doc.setAttribute(scrolldir, 'up');
  }

  // Return lastScrollTop
  return (lastScrollTop = Math.max(0, scrollTop));
};
