<template>
  <div
    :style="{
      '--section-bg-color': theme.bg,
      '--section-text-color': theme.text,
    }"
  >
    <BaseHeader />

    <main class="bg-color flex flex-col min-h-fill-available">
      <RouterView v-slot="{ Component, route }">
        <template v-if="Component">
          <Transition name="page" mode="out-in">
            <Suspense>
              <component
                :is="Component"
                :key="getKey(route.path)"
                class="relative"
              ></component>
              <template #fallback>Loading...</template>
            </Suspense>
          </Transition>
        </template>
      </RouterView>
    </main>
    <BaseFooter
      v-if="$route.name !== 'MapOverview' && $route.name !== 'MapLocation'"
    />
  </div>
</template>

<script setup lang="ts">
import '@/assets/css/main.scss';

import useSectionTheme from '@/composables/useSectionTheme';
const { theme } = useSectionTheme();

// remove map location from key, so the map view doesn't remount when navigating between locations + remove trailing slash
const getKey = (path: string) =>
  path.replace(/\/map\/[^/]+/, '/map').replace(/\/$/, '');
</script>

<style lang="scss">
.app {
  @apply text-body-md;
}

.bg-color {
  // perfomance optimization - https://www.smashingmagazine.com/2016/12/gpu-animation-doing-it-right/#animate-transform-and-opacity-properties-only
  // delaying the transition to reduce load as the css var is set
  transition-property: color;
  @apply text-[--section-text-color] duration-650 delay-100;

  &::before {
    // setting bg color on :before to optimize performance
    @apply content-[''] fixed top-0 left-0 w-full h-full min-h-[100lvh];
    @apply bg-[--section-bg-color] transition-colors duration-650 delay-100;
  }
}
</style>
