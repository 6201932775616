<template>
  <strong><slot /></strong>
</template>

<script>
export default {
  name: "SbStrong",
  props: {
    blok: {
      type: Object,
      required: false,
    },
  },
};
</script>
