<template>
  <p>
    <template v-for="item in blok.content" :key="item.id">
      <template v-if="item.marks">
        <MarkCompile :blok="item" :marks="item.marks" />
      </template>
      <template v-else>
        <component :blok="item" :is="item.type" />
      </template>
    </template>
  </p>
</template>

<script>
export default {
  name: "SbParagraph",
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
